.search-form {
    label {
        margin-bottom: 6px;
        color: #FFF;
        @include font-size(9);
    }
    .dropdown button {
        width: 100%;
        height: 30px;
        padding: 3px 28px 3px 8px;
        overflow: hidden;
        line-height: 24px;
        @include font-size(9);
        &:after {
            top: 8px;
            right: 8px;
        }
        & + .dropdown-menu {
            width: 100%;
            background: $light_blue;
            top: 30px;
            @include font-size(9);
            a {
                font-weight: 300;
            }
        }
    }
    .form-control {
        width: 100%;
        height: 30px;
        padding: 3px 8px;
        border: none;
        border-radius: 0;
        @include font-size(9);
    }    
}

.search-form__top {
    position: relative;
    height: 93px;
    background: $grey;
    padding: 16px 25px 16px 35px;
    h3 {
        @include font-size(14);
        margin: 8px 0 0 0;
        font-weight: 400;
        line-height: 110%;
        text-align: left;
        color: #FFF;
        span {
            display: block;
            font-weight: 300;            
        }
        @media screen and (max-width: 600px) {
            margin-bottom: 20px;    
        }
    }
    .search-form__button {
        width: 100%;
        height: 30px;
        padding: 3px 8px;
        background: $grey;
        border: 1px solid #FFF;
        border-radius: 0;
        line-height: 140%;
        color: #FFF;
        font-weight: 300;
        @include font-size(9);
        &:focus {
            outline: none;
        }
        &.active {
            background: $yellow;
            border-color: $yellow;
        }
        @media screen and (max-width: 600px) {            
            &:nth-of-type(2) {
                margin-bottom: 15px;
            }
        }
    }    
    button.search-form__field--go {
        width: 30px;
        height: 30px;
        margin-left: 6px;
        background: url("/images/go.svg") center center no-repeat;
        background-size: 30px 30px;
        border: none;
        outline: none;
        & + .button {
            display: none;
            color: #FFF;
            margin-top: 20px;
            @media screen and (max-width: 600px) {
                display: block;    
            }
        }
        @media screen and (max-width: 600px) {
            display: none;    
        }
    }
    @media screen and (max-width: 600px) {
        label.search-form__field--go {
            display: none;    
        }
    }
    .search-form__colours {
        position: absolute;
        width: 10px;
        left: 0;
        top: 0;
        background: $blue;
        height: 100%;
        span {
            display: block;
            width: 10px;
            height: 25%;
            &:first-child {
                background: $green;
            }
            &:nth-child(2) {
                background: $red;
            }
            &:nth-child(3) {
                background: $yellow;
            }
            &:nth-child(4) {
                background: $blue;
            }
        }
    }
    @media screen and (max-width: 600px) {
        height: auto;    
    }
}
.search-form__bottom {
    background: #F5F5F5;
    @include font-size(9);
    .search-form__more-options--link {
        background: #E6E6E6;
        height: 32px;
        padding: 5px 0;
        text-align: center;
        a {
            position: relative;
            display: inline-block;
            padding-right: 25px;
            color: $grey;
            text-decoration: none;
            &:after {
                position: absolute;
                right: 0;
                top: 0;
                width: 20px;
                height: 20px;
                content: "";
                display: block;
                background: url("/images/more_options.svg") center right no-repeat;
            }
            &.active:after {
                transform: scaleY(-1);
            }
        }
    }
    .search-form__more-options--form {
        display: none;
        padding: 20px 170px;
        label {
            color: $grey;
        }
        @media screen and (max-width: 600px) {
            padding: 20px 20px 5px 20px;
            input {
                margin-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        margin-bottom: 25px;
    }
}

.search-form__controls {
    margin-top: 50px;
    h2 {
        text-align: left;
        margin: 0 0 10px;
        @include font-size(16);
        span {
            color: $yellow;
        }
    }
    .search-form__pagination--text, .search-form__pagination--per-page {
        float: left;
    }
    .search-form__pagination--text {
        width: 380px;
        max-width: 100%;
        padding-top: 1px;
        strong {
            font-weight: 400;
        }
    }
    .search-form__pagination--per-page button {
        border: 1px solid #BBB; 
        padding: 3px 30px 3px 10px;
        @include font-size(9);
        &:after {
            top: 7px;
            right: 8px;
        }
        & + .dropdown-menu {
            width: 100%;
            min-width: inherit;
            top: 29px;
            border-left: 1px solid #BBB;
            border-right: 1px solid #BBB;
            border-bottom: 1px solid #BBB;
            a {
                @include font-size(9);
                padding: 5px 10px;                
            }
        }        
    }
    @media screen and (max-width: 600px) {
        margin: 0 0 25px 0;
        .search-form__pagination--per-page {
            float: right;    
        }
    }
    .property-search__views {
        list-style: none;
        margin: 15px 0 0;
        padding: 0;
        float: right;
        li {
            display: inline-block;
            margin-left: 25px;
            &[data-results-type=properties] {
                display: none;
            }
        }
        svg {
            height: 31px;
            path {
                fill: $light_grey;
            }
        }
        a.active svg path {
            fill: $yellow;
        }
        @media screen and (max-width: 600px) {
            float: none;
            text-align: center;
            li {
                display: inline-block;
                margin: 5px 10px;
                &:first-child {
                    display: none;
                }
            }
        }
    }
    .property-search__results-filters {
        list-style: none;
        margin: 15px 0 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        border-left: 1px solid #BBB;
        li {
            flex-grow: 1;
            @media screen and (max-width: 600px) {
                flex-basis: 50%;
                margin-top: -1px;    
            }
        } 
        a {
            display: block;
            padding: 8px 10px;
            border: 1px solid #BBB;
            border-left: none;
            text-align: center;
            color: $light_grey;
            text-decoration: none;
            &:hover {
                background: #F5F5F5;
            }
            &.active {
                background: $grey;
                color: #FFF;
            }
        }
        @media screen and (max-width: 600px) {
            margin: 25px 0;    
            a {
                height: 100%;
            }
        }
    }
}

.property-search__results {
    min-height: 350px;
    text-align: center;
    pre {
        text-align: left;
    }
    .loading {
        margin: 50px auto;
        display: inline-block;    
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        &.row {
            margin: 0 -15px;
        }
    }
    div[class*=property-search__results--] {
        display: none;
        &:first-of-type {
            display: block;
        }
    }
    .property-search__results--grid {
        display: block;
        .property-search__result {
            margin-bottom: 30px;
            background: #FFF;
            text-align: left;
        }
        figure {
            position: relative;
            text-align: left;
            min-height: 250px;
            background-size: cover;
            background-position: center center;
            border-bottom: 3px solid $blue;
            a {
                opacity: 0;
                background: rgba(0,155,202,0.75);
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                width: 100%;
                height: 100%;
                text-decoration: none;
                transition: ease-in-out opacity .25s;

                span {
                    color: white;
                }

                &:hover {
                    opacity: 1;
                }
            }
        }
        header {
            padding: 10px 0;
            border-bottom: 1px solid $blue;
            li {
                display: inline-block;
                padding: 0 20px;
            }
        }
        .property-search__results--info {
            display: block;
            padding: 20px;
            min-height: 128px;
            color: $grey;
            text-decoration: none;
            h4, h5, h6 {
                margin: 0;
            }
            h4 {
                font-weight: 400;
                @include font-size(12);
            }
            h5 {
                @include font-size(12);
                margin-bottom: 8px;
                font-weight: 300;
            }
            h6 {
                @include font-size(9);
                font-weight: 300;
            }
            a {
                color: $grey;
                text-decoration: none;
            }
        }
    }
    .property-search__results--list {
        & > ul > li {
            border-bottom: 1px solid #EEE;
            text-align: left;
            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
            }
        }
        .property-search__result {
            position: relative;
            .row {
                background: white;
                padding: 30px 15px;
                margin: 0;
                figure {
                    height: 100%;
                    min-height: 240px;
                    background-size: cover;
                    position: relative;
                    a {
                        opacity: 0;
                        background: rgba(0,155,202,0.75);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        text-decoration: none;
                        transition: ease-in-out opacity .25s;
                        span {
                            color: white;
                        }
                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                .col-sm-8 {
                    header {
                        max-height: 80px;
                        h4.blue {
                            color: $blue;
                        }
                        @media screen and (max-width: 600px) {
                            max-height: none;    
                        }
                    }                    
                    footer {
                        flex-basis: 100%;
                        align-self: flex-end;
                    }
                    @media screen and (max-width: 600px) {
                        p {
                            @include font-size(9);
                        }
                    }
                }
            }
            .scheme-properties, .scheme-properties-container {
                display: block;
            }
            @media screen and (max-width: 600px) {
                .scheme-properties-container {
                    padding: 15px;
                    h4 {
                        margin-top: 0;
                        float: none;
                    }
                    & > a {
                        float: none;
                        text-align: center;
                    }
                    table {
                        td {
                            @include font-size(8);
                        }
                    }
                }
            }
        }
        .property-search__results--title {
            width: 100%;
            display: block;
            position: relative;
            padding: 15px 0;
            color: $grey; 
            text-decoration: none;
            h4 {
                @include font-size(14);
                margin: 0 0 25px;
                font-weight: 300;
                span {
                    font-weight: 400;
                }
            }
            a {
                color: $grey;
                text-decoration: none;
            }
            &:before, &:after {
                position: absolute;
                display: block;
                content: "";
                top: 0;
                left: 0;
                width: 80px;
                border-top: 1px solid $blue;
                @media screen and (max-width: 600px) {
                    width: 100%;    
                }
            }
            &:after {
                top: auto;
                bottom: 25px;
                
            }
        }
        footer {
            width: 100%;
            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 25px;
                border-top: 1px solid $blue;
                border-bottom: 1px solid $blue;
                li {
                    display: inline-block;
                    padding: 10px 0;
                }
                a {
                    color: $grey;
                    text-decoration: none;                
                }
                img {
                    display: inline-block;
                    margin-left: 8px;
                    vertical-align: middle;
                }
            }
        }
    }
    .property-search__results--map {
        #property-search__map {
            text-align: left;
            height: 800px;
            margin-bottom: 25px;
        }    
    }
    
    .property-search__results--table {
        display: block;
    }
    
    table {
        width: 100%;
        background: #DDD;
        border-collapse: separate;
        border-spacing: 1px;
        margin-bottom: 50px;            
        td, th {
            padding: 8px 15px;
            text-align: left;
        }
        th {
            background: $light_grey;
            color: #FFF;
            font-weight: 300;
        }
        td {
            background: #FFF;
            cursor: pointer;
            transition: background .2s ease;
            circle {
                fill: $yellow;
            }
            path {
                stroke: #FFF;
            }                
        }
        /*tr[data-scheme] td {
            background: lighten($yellow, 40%);
        }*/
        tr:not(.scheme-properties) > td:last-child {
            text-align: center;
        }
        tr:not(.scheme-properties):hover td {
            background: $yellow;
            circle {
                fill: $grey;
            }
            a.view-properties {
                background: #FFF;
                color: $grey;
            }
        }
        a.view-properties {
            display: block;
            width: 95px;
            background: $yellow;
            padding: 5px 8px;
            border-radius: 8px;
            text-decoration: none;
            color: #FFF;
            @include font-size(8);

        }    
        @media screen and (max-width: 600px) {
                @include font-size(8);
                td, th {
                    padding: 6px 8px;
                    vertical-align: top;
                }
            }
    }
    
    .scheme-properties {
        display: none;
        & > td  { 
            padding: 0;
            cursor: auto;
        }
        .scheme-properties-container {
            display: none;
            padding: 25px;
            background: #EEE;
            h4 {
                @include font-size(10); 
                margin: 10px 0 15px;
                float: left;
            }
            & > a {
                float: right;
                display: block;
                background: $blue;
                color: #FFF;
                text-decoration: none;
                border-radius: 8px;
                padding: 8px 15px;
                margin-bottom: 15px;
                @include font-size(9);
            }
        }                    
        table {
            clear: both;
            margin: 0;
            td, th {
                padding: 8px 10px;
                @include font-size(9);
            }
            td {
                background: #FFF;                       
            }
            svg {
                height: 22px;
                width: 22px;
            }
        }
    }
    
}



.property-search__pagination {
    text-align: center;
    padding: 0;
    li {
        display: inline-block;
        margin: 0 3px;
    }
    a {
        display: block;
        padding-top: 1px;
        width: 25px;
        height: 25px;
        color: $grey;
        border-radius: 50%;
        background: transparent;
        text-decoration: none;
        &.active {
            background: $yellow !important;
            color: #FFF;
        }
        &:hover {
            background: #FFF;
        }
    }
}




.featured-properties .search-form__controls {
    text-align: center;
    .property-search__views {
        float: none;
        li {
            margin: 0 15px;
        }
    }
}