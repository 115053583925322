.gm-style * {
    box-sizing: content-box !important;
}

.wrapper {
    max-width: 1280px;
    margin: 0 auto;
    &.padded {
        padding: 0 40px;
        @media screen and (max-width: 600px) {
            padding: 0 20px;
        }
    }
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 350;
    width: 100%;
    height: 110px;
    padding: 26px 0;
    background: rgba(255,255,255,.95);
    border-bottom: 1px solid #EEE;    
    .header__surround {
        position: relative;
        max-width: 1600px;
        margin: 0 auto;
        .mobile__controls {
            display: none;
        }
        .social__icons {
            display: none;
        }
        @media screen and (max-width: 800px) {
            padding-top: 5px;
            .wrapper.padded {
                padding: 0;
                .header__logo {
                    margin-left: 20px;
                }
                .mobile__controls {
                    position: relative;
                    display: block;
                    a.search__link {
                        position: absolute;
                        top: 9px;
                        right: 75px;
                    }
                }              
                .header__nav {
                    width: 100%;
                    height: 100vh;
                    display: none;
                    background: #FFF;
                    position: relative;
                    z-index: 550;
                    margin-top: 12px;
                    border-top: 1px solid #DDD;
                    ul {                        
                        li {
                            display: block;
                            width: 50%;
                            float: left;
                            font-size: 24px;                            
                            margin: 0;
                            text-align: center;
                            a {
                                display: block;
                                padding: 8px 0;
                            }
                            &.social__icons {
                                display: flex;
                                justify-content: space-around;
                                a {
                                    flex-basis: 20%;
                                }
                            }
                        }
                    }
                }
            }
        }
        
        @media screen and (max-width: 500px) {
            .wrapper.padded {                       
                .header__nav {                    
                    margin-top: 15px;
                    ul {                        
                        li {
                            width: 100%;
                            float: none;
                        }
                    }
                }
            }
        }
        
        @media screen and (max-width: 600px) {
            .wrapper.padded nav {
                a.search__link {
                    position: static;
                }
            }
        }

        @media screen and (min-width: 768px) and (max-width: 1024px) {
            .wrapper.padded {
                padding: 0 10px;
            }
            .header__nav {
                width: 575px;
                ul li {
                    margin-left: 10px; 
                }
            }
        }
    }
    .header__logo {
        float: left;
        img {
            width: 170px;
            @media screen and (max-width: 480px) {
                width: 140px;
            }
        }
    }
    .header__nav {
        float: right;
        ul {
            margin: 16px 0 0;
            padding: 0;
            list-style: none;
        }
        li {
            display: inline-block;
            margin: 0 0 0 40px;
        }
        a {
            text-decoration: none;
            color: $grey;
            &.active {
                color: #BBB;
                svg g {
                    stroke: #BBB;
                }
            }
            @media screen and (max-width: 480px) {
                &.search__link {
                    display: none;
                }
            }
        }
        svg {
            position: relative;
            top: 2px;
            margin-left: 5px;
            width: 15px;
            height: 15px;
        }
    }
    .header__brochures {
        position: absolute;
        top: 84px;
        right: 0;
        z-index: 500;
        padding: 10px 25px 10px 20px;
        height: 44px;
        background: #003566;
        color: #FFF;
        text-decoration: none;
        i {
            color: $yellow;
        }
        &:before {
            position: absolute;
            display: block;
            content: "";
            left: -30px;
            top: 0;
            background: url(/images/angle.png);
            width: 30px;
            height: 44px;
        }
    }
    .header__brochures--container {
        display: none;
        position: absolute;
        top: 84px;
        right: 0;
        z-index: 450;
        width: 520px;
        height: 310px;
        padding: 65px 20px 40px;
        background: lighten(#003566, 3%);
        &.open {
            display: block;
        }
        .brochure {
            float: left;
            width: 33%;
            padding: 0 15px;
            a {
                text-decoration: none;
                &:hover img {
                    transform: scale(1.1);
                }
            }
            img {
                width: 100%;
                border: 1px solid #FFF;
                transition: transform .2s ease;
            }
            span {
                display: block;
                text-align: center;
                color: #FFF;
                margin-top: 8px;
                @include font-size(8);
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .header__brochures {
        width: 100%;
        text-align: center;        
    }
    .header__brochures--container.open {
        width: 100%;
        top: 128px;
        padding-top: 25px;
    }    
}



main {
    padding-top: 110px;
    @media screen and (max-width: 600px) {
        padding-top: 154px;    
    }
    p, li {
        a {
            color: $blue;
        }
    }
    &.property {
        padding-top: 172px;
        @media screen and (max-width: 600px) {
            padding-top: 223px;    
        }
    }
}

.hr-blocks {
    margin: 0 auto 30px;
    width: 75px;
    height: 15px;
    span {
        display: block;
        float: left;
        width: 15px;
        height: 15px;
        margin-right: 5px;
        border-width: 2px;
        border-style:  solid;
        background: transparent;
        &:first-child {
            border-color: $blue;
        }
        &:nth-child(2) {
            border-color: $yellow;
        }
        &:nth-child(3) {
            border-color: $red;
        }
        &:last-child {
            border-color: $green;
            margin-right: 0 !important;
        }
    }
    &:after {
        display: block;
        clear: both;
        content: "";
    }
}

.row.five-columns .column {
    max-width: 20%;
    flex: 0 0 20%;
}

.dropdown button {
    position: relative;
    border: none;
    background: #FFF;
    text-align: left;
    padding: 10px 50px 10px 20px;
    color: #333;
    & + .dropdown-menu {
        margin: 0;
        padding: 0;
        border: none;
        border-radius: 0;
        top: 45px;
        max-height: 270px;
        overflow: auto;
        input[type=search] {
            width: 100%;
            padding: 6px 15px;
            border-top: 1px solid #EEE;
            border-bottom: 1px solid #EEE;
        }
        a {
            padding: 6px 15px
        }
    }
    &:after {
        position: absolute;
        right: 20px;
        top: 16px;
        border: none;
        margin: 0;
        width: 15px;
        height: 15px;
        background-size: 15px 15px;
        background-repeat: no-repeat;
        background-image: url(/images/dropdown.svg);
        @media screen and (max-width: 480px) {
            background-image: url(/images/dropdown-orange.svg);
        }
    }
}

.search {
    display: none;
    position: fixed;
    left: 0;
    z-index: 350;
    width: 100%;
    height: 100%;
    padding: 40px;
    background: $blue;
    text-align: center;
    color: #FFF;
    @media screen and (max-width: 480px) {
        top: 0;
        overflow: scroll;
        height: 100vh;
    }
    h2 {
        @include font-size(24);
        margin: 10px 0 30px;
        font-weight: 300;
        span {
            display: block;
            font-weight: bold;
            font-weight: 400;
        }
    }
    h3 {
        margin: 60px 0 20px;
        @include font-size(14);
        font-weight: 300;
    }
    .search__fields {
        max-width: 1110px;
        margin: 0 auto;
        text-align: left;
    }
    .dropdown button {
        width: 100%;
        height: 37px;
        padding: 2px 35px 8px 12px;
        margin: 5px 0 25px;
        line-height: 34px;
        overflow: hidden;
        @include font-size(9);
        & + .dropdown-menu {
            top: 42px;
            width: 100%;
            background: $light_blue;
            @include font-size(9);
            overflow-x: hidden;
        }
        &:after {
            right: 12px;  
            top: 11px;
        }
    }
    .form-control {
        height: 37px;
        padding: 8px 12px;
        margin: 5px 0 25px;
        border-radius: 0;
        border: none;
        @include font-size(9)
    }

    .form-control.error {
        border: 2px solid red;
    }

    .button {
        color: #FFF;
        &:hover {
            color: #F5F5F5;
        }
    }
    .search__close {
        display: block;
        position: absolute;
        top: 40px;
        right: 40px;        
    }
    .search__featured {
        max-width: 1110px;
        margin: 0 auto 20px;
        figure {
            position: relative;
            min-height: 250px;
            figcaption {
                opacity: 0;;
                position: absolute;                
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transition: opacity .2s ease-in-out;
                background: rgba(0,0,0,.7);
            }
            a {
                position: relative;
                display: flex;
                text-align: center;
                align-items: center;
                width: 100%;
                height: 100%;
                text-decoration: none;               
                h4 {
                    @include font-size(12);
                    line-height: 130%;
                    font-weight: 300;
                    width: 100%;
                    margin: 0;
                    color: #FFF;
                    span {
                        display: block;
                        font-weight: 400;
                    }
                }                
                &:before, &:after {
                    position: absolute;
                    content: "";
                    display: block;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 1px;
                    width: 20%;
                    background: $yellow;
                }
                &:before {
                    top: 80px;
                }
                &:after {
                    bottom: 80px;
                }
            }
            &:hover figcaption {
                opacity: 1;
            }
        }
    }
    
}

body.open {
    @media screen and (max-width: 600px) {
        overflow: hidden;
        .header {
            display: none;
        }
    }
    main > * {
        display: none;
    }
    .search {
        display: block;
    }
}

.hero {
    position: relative;
    max-height: 60vh;
    min-height: 550px;
    padding: 80px 80px;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
    .hr-blocks {
        width: 172px;
        height: 28px;
        margin-bottom: 50px;
        span {
            width: 28px;
            height: 28px;
            border-width: 3px;
            margin-right: 20px;
        }
    }
    h1 {
        text-align: center;
        text-shadow: 2px 2px 2px #333;
        color: #FFF;
        margin-bottom: 25px;
        font-size: 68px;
        font-weight: 300;
    }
    h2 {
        text-align: center;
        font-size: 24px;
        font-weight: 300;
        line-height: 1.5;
        margin: 0 auto;
        color: #FFF;
        max-width: 730px;
    }
    .hero__arrow {
        display: block;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: rgba(255,255,255,.7);
        margin: 50px auto 0;
        text-align: center;
        padding-top: 30px;
        transition: background ease-in .2s;
    }
    &.our-team {
        background-image: url(/images/hero/our-team.jpg);
    }
    &.our-clients {
        background-image: url(/images/hero/our-clients.jpg);
        padding-top: 80px !important;
    }
    &.services {
        background-image: url(/images/hero/services.jpg);
    }
    &.case-studies {
        background-image: url(/images/hero/case-studies.jpg);
    }
    &.news {
        background-image: url(/images/hero/news.jpg);
    }
    &.property__hero {
        min-height: 600px;
    }
}

@media screen and (max-width: 600px) {
    .hero {
        padding: 60px 25px 35px;
        max-height: none;
        min-height: 400px;
        .hr-blocks {
            margin: 30px auto;
        }
        h1 {
            font-size: 45px;
        }
        h2 {
            font-size: 19px;
        }
        .hero__arrow {
            display: none;    
        }
        &.property__hero {
            min-height: 350px;
        }
    }    
}

.carousel {
    .carousel-item {
        align-items: flex-start;
    }
    .carousel-indicators {
        left: 40px;
        bottom: auto;
        right: auto;
        top: 50%;
        z-index: 200;
        display: block;
        margin: 0;
        transform: translateY(-50%);
        li {
            height: 18px;
            width: 18px;
            margin: 15px 0;
            border-radius: 50%;
            background: #FFF;
            border: 5px solid #FFF;
            &.active {
                background: #333;
            }
        }
    }
}

.carousel-fade .carousel-inner .carousel-item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

/*
  WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
  Need to override the 3.3 new styles for modern browsers & apply opacity
*/
@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .carousel-item.next,
    .carousel-fade .carousel-inner > .carousel-item.active.right {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .carousel-item.prev,
    .carousel-fade .carousel-inner > .carousel-item.active.left {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .carousel-item.next.left,
    .carousel-fade .carousel-inner > .carousel-item.prev.right,
    .carousel-fade .carousel-inner > .carousel-item.active {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
}

.panel {
    position: relative;    
    padding: 90px 0;
    background: #FFF;
    @media screen and (max-width: 480px) {
        padding: 45px 0;
    }
    &.arrow {
        &:first-of-type {
            padding-top: 80px;
            @media screen and (max-width: 600px) {
                padding-top: 40px;    
            }
        }
        &:after {
            position: absolute;
            content: "";
            display: block;
            width: 60px;
            border-top: 30px solid #FFF;
            border-left: 40px solid transparent;
            border-right: 40px solid transparent;
            background: transparent;
            bottom: -30px;
            left: 50%;
            z-index: 100;
            transform: translateX(-50%);
        }
        &.panel__raised:after {
            bottom: 70px;
        }
        @media screen and (max-width: 600px) {
            &:last-of-type {
                padding-bottom: 60px;
                &:after {
                    display: none;
                }
            }
        }
        
    }
    &.split {
        padding: 0;
        h2, h3, p {
            text-align: left;
        }
        hr, .row {
            margin-left: 0;
            margin-right: 0;
        }
        .col-sm-6 {
            padding: 90px 0;
        }
        .split__padded {
            padding-right: 40px;
        }
    }
    &.panel__raised {
        padding: 0 !important;
        margin-bottom: 0;
        .panel__raised--overhang {
            position: relative;
            top: -100px;
            background: #FFF;
            padding: 50px 40px 70px;            
            max-width: 850px;
            margin: 0 auto 0;
        }
    } 
    &.small-bottom-margin {
        padding-bottom: 35px;
        @media screen and (max-width: 600px) {
            padding-bottom: 0;    
        }
    }
    &.small-top-margin {
        padding-top: 35px;
    }
    &.blue {
        background: $light_blue;
        &:after {
            border-top-color: $light_blue;
        }
    }
    &.grey {
        background: #F5F5F5;
        &:after {
            border-top-color: #F5F5F5;
        }
    }
    .panel__content {
        max-width: 700px;
        margin: 0 auto;
        &.panel__content--centred {
            text-align: center;
            .button {
                margin-top: 40px;
            }
            @media screen and (max-width: 480px) {
                text-align: left;
            }
        }
        &.panel__content--supplementary {
            text-align: left;
            h2 {
                @include font-size(20);
                text-align: left;
                color: $blue;
                margin: 30px 0 20px;
            }
        }
        &.panel__content--cols {
            column-count: 2;
            column-gap: 30px;
            @media screen and (max-width: 480px) {
                column-count: 1;
            }
        }
        &.panel__content--carousel {
            max-width: 850px;
            .carousel {
                max-width: 850px;
                margin-left: auto;
                margin-right: auto;
            }
            .carousel-control-prev, .carousel-control-next {
                z-index: 180;
                left: -15%;
                background: url("/images/carousel_control.svg") center center no-repeat;
            }
            .carousel-control-next {
                right: -15%;    
                left: auto;
                transform: scaleX(-1);
            }
        }
    }
    hr {
        max-width: 80px;
        background: $grey;
        margin-bottom: 40px;
        @media screen and (max-width: 480px) {
            margin-left: 0;
        }
    }
    h2, h3 {
        text-align: center;
        margin: 0 0 40px 0;
        color: $grey;
        @include font-size(24);
        font-weight: 300;
        @media screen and (max-width: 480px) {
            text-align: left;
        }
    }
    h3 {
        max-width: 700px;
        margin: 0 auto 30px;
        color: $blue;
        font-weight: 300;
        line-height: 160%;
        @include font-size(16);
    }
    p {
        line-height: 170%;
        & + .button {
            margin-top: 15px;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    @media screen and (max-width: 600px) {
        .hr-blocks {
            margin: 0 0 30px 0;
        }
        h2 {
            @include font-size(20);
        }
        h3 {
            @include font-size(14);
        }
    }
}

.centered {
    text-align: center;
}

.button {
    display: inline-block;
    padding: 12px 0;
    border: none;
    border-top: 1px solid $yellow;
    border-bottom: 1px solid $yellow;
    text-transform: uppercase;
    text-decoration: none !important;
    background: transparent;
    letter-spacing: 1px;
    color: $grey;
    @include font-size(12);
    &:hover {
        text-decoration: none;
        color: $light_grey;
    }
    @media screen and (max-width: 480px) {
        text-align: center;
        width: 100% !important;
    }
    &.center {
        margin: 0 auto;
    }
}

.slide:not(.carousel) {
    figure {
        position: relative;
        margin-bottom: 30px;
        background-size: cover;
        overflow: hidden;
    }       
    a:not(.anchor-link) {
        text-decoration: none;
        color: #444;
        display: block;
        min-height: 400px;
        background: transparent;
        transition: ease-in-out background .25s;
        line-height: 120%;
        span {
            display: block;
            @include font-size(10);
        }
        &:hover {
            background: rgba($blue, .75);
            .button {
                opacity: 1;
            }
        }
    }
    .button {
        position: absolute;
        top: 40%;
        left: 50%;
        color: #FFF;
        transform: translateX(-50%) translateY(-50%);
        opacity: 0;
        transition: ease-in-out opacity .25s; 
    }
    figcaption {
        position: absolute;
        bottom: 0;
        height: 70px;
        width: 100%;        
        border-top: 3px solid $yellow;
        border-bottom: 1px solid $yellow;
        background: #FFF;
        transition: height .3s linear;
        @include font-size(14);
        a {
            display: block;
            position: relative;
            z-index: 80;
            height: 66px;
            padding: 17px 20px;
            background: #FFF;
            color: $grey;
            text-decoration: none !important;
        } 
        .slide__content {
            padding: 0 20px;
            p {
                @include font-size(10);
                margin: 0;
            }
        }
        &:before {
            position: absolute;
            content: "";
            display: block;
            top: -25px;
            left: 20px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: url("/images/expand.svg") center 10px no-repeat $yellow;
        }
        &.open {
            height: 230px;
            &:before {
                transform: scaleY(-1);
                background-position: center 21px;
            }
        }
    }
    &.no-slide-up {
        figcaption {
            position: relative;
            &:before {
                display: none;
            }
            a {
                min-height: inherit;
                &:hover {
                    background: #FFF;
                }
            }
        }
    }
}


.footer {
    float: left;
    width: 100%;
    padding: 50px 0;
    background: $grey;
    color: #FFF;
    h5 {
        @include font-size(12);
        margin: 0 0 20px 0;
        font-weight: 400;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    li a, p, p a {
        color: #FFF;
        @include font-size(9);
    }
    
    p:last-of-type {
        margin: 0;
    }
    .footer__shift-down {
        margin-top: 41px;
    }
    .footer__social li {
        display: inline-block;
        margin: 0 15px 0 0;
        a {
            display: block;
            width: 30px;
            height: 30px;
            padding-top: 2px;
            margin-bottom: 30px;
            background: #FFF;
            border-radius: 50%;
            color: $grey;
            text-align: center;
            @include font-size(12);
            &.retail, &.office {
                position: relative;
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: -20px;
                    left: -3px;
                    color: #FFF;
                    width: 100%;
                    text-align: center;
                    letter-spacing: 1px;
                    @include font-size(8);
                }
            }
            &.retail:after {
                content: "RETAIL";
            }
            &.office:after {
                content: "OFFICE";
            }
        }
    }
    
    .footer__credit {
        margin-top: 25px !important;
        &, a {
            @include font-size(8);
        }
    }

    @media screen and (max-width: 600px) {
        padding-bottom: 0;
        padding-top: 55px;
        margin-top: 0;
        position: relative;
        .footer-contents {
            display: none;
            div[class*=col] {
                flex-basis: 100%;
                max-width: 100%;
                padding-bottom: 1em;
                h5 {
                    text-align: center;
                }
                ul {
                    width: 100%;
                }
                li, p {
                    text-align: center;
                    a {
                        font-size: 16px;
                    }
                }
            }
        }
        .footer__shift-down {
            margin-top: 0;
        }
        .social {
            display: none;
        }
        .footer__mobile__toggle,
        .footer__social {
            display: flex;
            li a {
                &.retail, &.office {
                    &:after {
                        left: 0;
                    }
                }
            }
        }

    }
}
.footer__mobile__toggle {
    width: 80px;
    height: 80px;
    background-color: $grey;
    position: absolute;
    left: calc(50% - 40px);
    top: -30px;
    z-index: 150;
    border-radius: 50%;
    display: none;
    transition: transform .3s linear;
    img {
        width: 50%;
        margin: auto;
    }
    &.open {
        transform: rotate(45deg);
    }
}
.row.footer__social {
    position: relative;
    background-color: $grey;
    display: none;
    @media screen and (max-width: 480px) {
        display: flex;
    }
    div {
        display: flex;
        img {
            flex-basis: 50px;
        }
    }
}

@media screen and (max-width: 600px) {
    .footer__social .social {
        text-align: center;
        padding-bottom: 40px;
        ul {
            display: inline-block;
            margin: 0 auto;
            li {
                margin: 0 8px;
                a {
                    width: 65px;
                    height: 65px;
                    font-size: 36px;
                    padding-top: 5px;
                }
            }
        }
    }
}


.anchor-link {
    position: relative;
    a {
        position: absolute;
        left: 0;
        top: -110px;
    }
    &.property a {
        top: -172px;
    }
}

.parallax-window {
    min-height: 600px;
    background: transparent;
    &.arrow {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: -1px;
            width: calc(100% + 1px);
            height: 30px;
            background-image: url(/images/parallax_arrow.png);
            background-position: bottom center;
        }
        &.blue:after {
            background-image: url(/images/parallax_arrow_blue.png);
        }
    }
    @media screen and (max-width: 600px) {
        min-height: 300px;    
    }
}

// Bootstrap push 1.5 column
.push-sm-1-5, .push-md-1-5 {
    left: 12.499995%;
}

.hamburger {
    float:  right;
    padding-right:  20px;
    @media screen and (min-width: 800px) {
        display: none;
    }
    .line{
        width: 25px;
        height: 2px;
        background-color: #555759;
        display: block;
        margin: 10px auto;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        &:nth-child(2) {
            width: 15px;
            margin-right: 0;
        }
    }
    &:hover{
        cursor: pointer;
    }
}

/* ONE */

#hamburger-1.is-active .line:nth-child(2){
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1){
  -webkit-transform: translateY(10px) rotate(45deg);
  -ms-transform: translateY(10px) rotate(45deg);
  -o-transform: translateY(10px) rotate(45deg);
  transform: translateY(10px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3){
  -webkit-transform: translateY(-14px) rotate(-45deg);
  -ms-transform: translateY(-14px) rotate(-45deg);
  -o-transform: translateY(-14px) rotate(-45deg);
  transform: translateY(-14px) rotate(-45deg);
}



.menu--cordelia .menu__item {
	position: relative;
}

.menu--cordelia a {
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
    &:hover {
        color: #000;
    }
}

.menu--cordelia .menu__item--current {
    &::before, &::after {
        background: #CCC;
    }
    a {
        color: #CCC;
        -webkit-transition: color 0.6s;
        transition: color 0.6s;
        &:hover {
            color: #000;
        }
    }
}

.menu--cordelia .menu__item--current::before,
.menu--cordelia .menu__item--current::after,
.menu--cordelia .menu__item:hover::before,
.menu--cordelia .menu__item:hover::after {
	content: '';
	position: absolute;
	z-index: 10;
	width: 100%;
	height: 2px;
    background: $yellow;
	-webkit-transform: scale3d(0, 1, 1);
	transform: scale3d(0, 1, 1);
	-webkit-animation-fill-mode: initial;
	animation-fill-mode: initial;
}

.menu--cordelia .menu__item--current::before,
.menu--cordelia .menu__item--current::after {
    background: #CCC;
}

.menu--cordelia .menu__item:hover::before,
.menu--cordelia .menu__item--current::before {
	top: -5px;
	right: 0;
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
}

.menu--cordelia .menu__item:hover::after,
.menu--cordelia .menu__item--current::after{
	bottom: -6px;
	left: 0;
	-webkit-transform-origin: 0% 50%;
	transform-origin: 0% 50%;
}

.menu--cordelia .menu__item:hover::before,
.menu--cordelia .menu__item--current::before {
	-webkit-animation: anim-cordelia-top 0.6s linear both;
	animation: anim-cordelia-top 0.6s linear both;
}

.menu--cordelia .menu__item:hover::after,
.menu--cordelia .menu__item--current::after {
	-webkit-animation: anim-cordelia-bottom 0.6s linear both;
	animation: anim-cordelia-bottom 0.6s linear both;
}

@-webkit-keyframes anim-cordelia-top {
	0% {
		-webkit-transform: scale3d(0, 1, 1);
		transform: scale3d(0, 1, 1);
	}

	10% {
		-webkit-transform: scale3d(0.05, 1, 1);
		transform: scale3d(0.05, 1, 1);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	50% {
		-webkit-transform: translate3d(-95%, 0, 0) scale3d(0.05, 1, 1);
		transform: translate3d(-95%, 0, 0) scale3d(0.05, 1, 1);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
		transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
	}
}

@keyframes anim-cordelia-top {
	0% {
		-webkit-transform: scale3d(0, 1, 1);
		transform: scale3d(0, 1, 1);
	}

	10% {
		-webkit-transform: scale3d(0.05, 1, 1);
		transform: scale3d(0.05, 1, 1);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	50% {
		-webkit-transform: translate3d(-95%, 0, 0) scale3d(0.05, 1, 1);
		transform: translate3d(-95%, 0, 0) scale3d(0.05, 1, 1);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
		transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
	}
}

@-webkit-keyframes anim-cordelia-bottom {
	0% {
		-webkit-transform: scale3d(0, 1, 1);
		transform: scale3d(0, 1, 1);
	}

	10% {
		-webkit-transform: scale3d(0.05, 1, 1);
		transform: scale3d(0.05, 1, 1);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	50% {
		-webkit-transform: translate3d(95%, 0, 0) scale3d(0.05, 1, 1);
		transform: translate3d(95%, 0, 0) scale3d(0.05, 1, 1);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
		transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
	}
}

@keyframes anim-cordelia-bottom {
	0% {
		-webkit-transform: scale3d(0, 1, 1);
		transform: scale3d(0, 1, 1);
	}

	10% {
		-webkit-transform: scale3d(0.05, 1, 1);
		transform: scale3d(0.05, 1, 1);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	50% {
		-webkit-transform: translate3d(95%, 0, 0) scale3d(0.05, 1, 1);
		transform: translate3d(95%, 0, 0) scale3d(0.05, 1, 1);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
		transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
	}
}



.panel .home__our-services {
    @media screen and (max-width: 480px) {
        margin-top: 25px;
        h4 {
            text-align: left;
            margin-bottom: 10px;
        }
    }
}