.panel__content.our-team__filters {
    padding: 40px 0;
    max-width: none;
    @media screen and (max-width: 480px) {
        display: none;
    }
    ul {
        
        list-style: none;        
        padding: 0;
    }
    a {
        width: 100%;
        margin-bottom: 20px;
        border-radius: 0;
        color: $grey;
        border: 1px solid $blue;
        outline: none;
        font-weight: 300;
        &:hover, &.active {
            background: $blue;
            border-color: $blue;
            color: #FFF;
        }
        &:focus {
            box-shadow: none;
        }        
    }
    .show-all {
        text-align: center;
        a {
            display: inline-block;
            width: 25%;
        }
    }
}

.dropdown.our-team__filters {
    display: none;
    @media screen and (max-width: 480px) {
        display: block;
        width: 100%;
        button, .dropdown-menu {
            width: 100%;
        }
    }
}

.slide:not(.carousel).our-team__members {
    .anchor {
        padding-top: 110px;
        margin-top: -110px;
        pointer-events: none;
    }
    .member {
        margin-bottom: 30px;
    }
    figure {
        min-height: 400px;
        margin-bottom: 0;
        background-position: center;
    } 
    figcaption {
        height: 75px;
        &.open {
            height: 375px;
            a span {
                color: $blue;
            }
        }
        .surround {
            position: relative;
            height: 100px;
            background: #FFF;
            font-weight: 400;
            padding: 14px 20px;
            @include font-size(14);
            span {
                display: block;
                font-weight: 300;
                @include font-size(9);
            }
        }        
    }
    .slide__content {
        padding: 0 20px 20px;
        border-bottom: 1px solid $yellow;
        max-height: 250px;
        overflow: auto;
        p {
            @include font-size(9);
            line-height: 130%; 
        }
        &:last-of-type {
            padding-bottom: 0;
            border-bottom: 0;
        }
        &.slide__content--buttons {
            padding: 0;
            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
            }
            a {
                display: block;
                background: #FFF;
                position: relative;
                min-height: 48px;
                height: auto;
                padding: 15px 0 12px 60px;
                @include font-size(9);
                font-weight: 300;
                color: #333;
                img {
                    position: absolute;
                    left: 20px;
                    top: 10px;
                }
                &:hover {
                    background: $light_yellow;
                    text-decoration: none;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .dropdown.our-team__filters {
        h4 {
            margin: 40px 0 25px;
        }
    }
}