.contact__hero {
    padding: 0 !important;
    #contact__hero--map {
        height: 500px;
        @media screen and (max-width: 480px) {
            height: 250px;
        }
    }
}
.contact__content {
    h4 {
        margin: 0 0 10px;
        text-align: left;
        @include font-size(14);
        font-weight: 300;
        color: $blue;
    }
    strong {
        font-weight: 400;
    }
}