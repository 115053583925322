.home-carousel {
    background-color: black;
    position: relative;
    
    .bx-wrapper {
        margin-bottom: 0;   
        .bx-viewport {
            border: none;
            left: 0;
            box-shadow: none;
        }
    }
    @media screen and (max-width: 480px) {
        .bx-viewport,
        .bxslider div,
        .inner-content,
        img.d-block {
          height: 350px !important;
        } 
    }
    
    .bx-wrapper .bx-pager {
        left: 0;
        top: 5%;
        height: 50px;
        @media screen and (max-width: 600px) {
          top: initial;
          bottom: -20%;
        }

        .bx-pager-item {
            display: inline-block;
            border-radius: 0;
            border-width: 3px !important;
            border-style: solid;
            height: 28px;
            width: 28px;
            margin: 0 10px;
            
            a {
                display: block;
                border-radius: 0;
                height: 25px;
                width: 25px;
                margin: 0;
                background: transparent;
                &:hover {
                    background: transparent;
                }
            }
            &:first-child {
                border-color: $blue;
            }
            &:first-child a.active {
                background-color: $blue;
            }
            &:nth-child(2) {
                border-color: $yellow;
            }
            &:nth-child(2) a.active {
                background-color: $yellow;
            }
            &:nth-child(3) {
                border-color: $red;
            }
            &:nth-child(3) a.active {
                background-color: $red;
            }
            &:nth-child(4) {
                border-color: $green;
            }
            &:nth-child(4) a.active {
                background-color: $green;
            }
        }
        
    }

    .home-carousel__overlay {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 150;
        padding: 50px;
        h1 {
            margin: 0;
            text-align: center;
            text-shadow: 2px 2px 2px #333;
            color: #FFF;
            @include font-size(42);
            font-weight: 300;
        }
        
    }
    .home-carousel__arrow {
        position: absolute;
        bottom: 50px;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: rgba(255,255,255,.7);
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        padding-top: 30px;
        transition: background ease-in .2s;
        @media screen and (max-width: 1024px) {
          display: none;
        }
    }
}

.home__teams figcaption:before {
    display: none;
}

.home__our-services {
    h4 {
        margin: 40px 0 8px 60px;
        @include font-size(12);
        font-weight: 300;
        @media screen and (max-width: 600px) {
          margin: auto;
          text-align: center;
        }
    }
    .dropdown {
        button {
            position: relative;
            width: calc(100% - 60px);
            padding: 14px 20px;
            margin-left: 60px;
            border: 1px solid #CCC;
            background: #FFF;
            text-align: left;
            @media screen and (max-width: 600px) {
              margin: auto;
              width: 100%;
            }

            & + .dropdown-menu {
                margin: 0;
                padding: 0;
                width: calc(100% - 60px);
                border: none;
                border-radius: 0;
                top: 54px;
                left: 60px;
                background: #fff;
                border: 1px solid #ccc;
                border-top: 0;
                max-height: 400px;
                @media screen and (max-width: 600px) {
                    left: 0;
                    width: 100%;
                }
                a {
                    padding: 6px 20px;
                    font-weight: 300;

                    &:hover {
                        background-color: $blue;
                        color: white;
                    }
                }
                &[x-placement="top-start"] {
                    border-top: 1px solid #CCC;
                    border-bottom: 0;
                }
            }
            &:after {
                position: absolute;
                right: 20px;
                top: 18px;
                border: none;
                margin: 0;
                width: 15px;
                height: 15px;
                background-size: 15px 15px;
                background-repeat: no-repeat;
                background-image: url(/images/dropdown.svg);
            }
        }
    }
    .button {
        margin-top: 69px;
        @media screen and (max-width: 600px) {
          margin-top: 20px;
          width: 100%;
        }
    }
}

/* Carousel Fading slide */
.carousel-fade .carousel-inner { background: #000; }
.carousel-fade .carousel-control { z-index: 2; }  
.carousel-fade .carousel-item {
  opacity: 0;
  transition: opacity 4s ease-in;

  }

  .carousel-fade .next.left,
  .carousel-fade .prev.right,
  .carousel-fade .carousel-item.active { opacity: 1; }

  .carousel-fade .active.left,
  .carousel-fade .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
    }


/* Safari Fix */
@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .carousel-item.next,
    .carousel-fade .carousel-inner > .carousel-item.active.right {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .carousel-item.prev,
    .carousel-fade .carousel-inner > .carousel-item.active.left {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .carousel-item.next.left,
    .carousel-fade .carousel-inner > .carousel-item.prev.right,
    .carousel-fade .carousel-inner > .carousel-item.active {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
}




/* Carousel Control custom */
.carousel-control .control-icon {
  font-size: 48px;
  height: 30px;
  margin-top: -15px;
  width: 30px;
  display: inline-block;
  position: absolute;
  top: 50%;
  z-index: 5;
  }
  .carousel-control .prev { margin-left:  -15px;  left: 50%; } /* Prev */
  .carousel-control .next { margin-right: -15px; right: 50%; } /* Next */


/* Removing BS background */
.carousel .control-box { opacity: 0; }
a.carousel-control.left  { left: 0; background: none; border: 0;}
a.carousel-control.right { right: 0; background: none; border: 0;}


/* Animation */
.control-box, a.carousel-control, .carousel-indicators li {
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;   
  /* hardware acceleration causes Bootstrap carousel controlbox margin error in webkit */
  /* Assigning animation to indicator li will make slides flicker */
  } 


/* Hover animation */
.carousel:hover .control-box { opacity: 1; }
.carousel:hover a.carousel-control.left { left: 15px; }
.carousel:hover a.carousel-control.right { right: 15px; }  

.carousel-item.active {
  position: relative;
}

.inner-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 100%;
    max-width: 1000px;
    padding: 50px;
    box-sizing: border-box;
    text-align: center;

    h1 {
      font-size: 68px;
      font-weight: 100;
      @media screen and (max-width: 1024px) {
        font-size: 52px;
      }
      @media screen and (max-width: 730px) {
        font-size: 38px;
      }
      @media screen and (max-width: 60px) {
        margin-bottom: 25px;
      }
    }

    p {
      font-size: 24px;
      max-width: 600px;
      margin: auto;
      @media screen and (max-width: 1024px) {
        font-size: 18px;
      }
      @media screen and (max-width: 600px) {
        font-size: 19px;
      }
    }

    a {
        width: 200px;
        display: block;
        margin: auto;
        text-align: center;
        color: white;
        margin-top: 2em;
    }
    @media screen and (max-width: 730px) {
        padding: 30px 15px;      
    }
  }

  h1, p {
    color: white;
  }
}

.teams-panel .two-cols {
  column-count: 2;
  height: 6rem;
  p {
    font-size: 18px;
  }
}

.flex-row.service-icons {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;

  @media screen and (max-width: 600px) {
      justify-content: space-between;
    }

  .service-icon {
    flex-basis: 20%;
    background-color: #003566;;
    box-sizing: border-box;
    padding: 20px 0;
    text-align: center;
    min-height: 200px;
    transition: background-color 0.2s ease;
    text-decoration: none;
    color: white;
    font-size: 18px; 
      
    &:nth-of-type(even) {
      background-color: #7fcde4;
    }

    @media screen and (max-width: 600px) {
      min-height: none;
      font-size: 13px;
      &:nth-child(10n+2),
      &:nth-child(10n+3),
      &:nth-child(10n+6),
      &:nth-child(10n+7),
      &:nth-child(10n+10) {
        background-color: #7fcde4;
      }
      &:nth-child(10n+1),
      &:nth-child(10n+4),
      &:nth-child(10n+5),
      &:nth-child(10n+8),
      &:nth-child(10n+9) {
        background-color: #003566;;
      }
    }

    @media screen and (max-width: 768px) {
      flex-basis: 33%;
    }

    @media screen and (max-width: 600px) {
      flex-basis: 50%;
    }

    p {
      line-height: 1.3;
    }


    
    &:nth-child(4n+1) {
        &:hover, &.active {
            .icon-wrapper {
                background-color: $blue;
            }
        }
    }
    &:nth-child(4n+2) {
        &:hover, &.active {
            .icon-wrapper {
                background-color: $yellow;
            }
        }
    }
    &:nth-child(4n+3) {
        &:hover, &.active {
            .icon-wrapper {
                background-color: $red;
            }
        }
    }
    &:nth-child(4n+4) {
        &:hover, &.active {
            .icon-wrapper {
                background-color: $green;
            }
        }
    }

    .icon-wrapper {
        max-width: 90px;
        max-height: 90px;
        width: 60%;
        margin: 0 auto 20px auto;
        border-radius: 50%;
        transition: background-color 0.2s ease;
        img {
            max-width: 90px;
            max-height: 90px;
        }
    }

  }
}