#about__carousel {
    margin-top: 50px;
}
.about__services {
    margin-top: 25px;
}
.about__services-background {
    background-size: cover;
}
.about__case-studies {
    background: #003566;
    margin-bottom: 20px;
    color: #FFF;
    .case-study {
        width: 100%;
        min-height: 510px;
    }
    .case-study__image {
        background-size: cover;
        background-position: center center;        
    }
    .case-study__content {
        padding: 50px 35px;
        text-align: left;
        min-height: 510px;
        h2, h3, p {
            text-align: left;
        }
        hr {
            margin: 0 0 25px;
        }
        h2 {
            @include font-size(18);
            margin-bottom: 25px;
            color: #FFF;
        }
        h3 {
            @include font-size(14);
            margin-bottom: 10px;
        }
        p, .button {
            @include font-size(10);
        }
    }
    @media screen and (max-width: 600px) {
        .case-study, .case-study__content {
            min-height: none;
        }
        .case-study__image {
            min-height: 300px;   
        }
    }
}