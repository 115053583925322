@import "helpers/mixins";
@import "helpers/placeholders";
@import "helpers/typography";
@import "helpers/variables";

@import "base/reset";

html, body {
    width: 100%;
    height: 100%;
    font-family: $font;
    font-weight: 300;
}

body {
    position: relative;
    max-width: 1600px;
    margin: 0 auto;
}

@import "layout/common";
@import "layout/home";
@import "layout/about";
@import "layout/our_team";
@import "layout/services";
@import "layout/clients";
@import "layout/search";
@import "layout/scheme";
@import "layout/news";
@import "layout/contact";
@import "layout/case_studies";