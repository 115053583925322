.case_study__card {
    height: 350px;
    margin-bottom: 30px;
    overflow: hidden;
    cursor: pointer;
    
    .sector {
        position: absolute;
        z-index: 100;
        text-align: center;
        left: 15px;
        width: calc(100% - 30px);
        letter-spacing: 1px;
        padding: 5px 20px;
        background: $blue;
        color: #FFF;
    }
    
    .image {
        height: 270px;
        background-size: cover;
        background-color: #545759;
        background-position: center center;
        position: relative;
        .button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            color: #FFF;
            opacity: 0;
            transition: ease-in-out opacity .25s; 
            z-index: 100;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            background: rgba($blue, .75);
            transition: ease-in-out opacity .25s;
        }
    }
    
    &:hover {
        .image {
            .button {
                opacity: 1;
            }
            &:after {
                opacity: 1;
            }
        }
    }

    .rollover {
        position: relative;
        background: white;
        margin-top: 0;
        left: 0;
        will-change: background;
        transition: transform .2s ease-in, background .2s ease-in;
        min-height: 350px;
        border-top: 3px solid $yellow;
        
        .inner {
            padding: 20px;
            background: #FFF;
            position: relative;
            z-index: 100;
            transition: background .2s ease-in;
        }

        h3 {
            font-size: 19px;
            color: black;
            line-height: 120%;
        }
        
        &:before {
            position: absolute;
            content: "";
            display: block;
            top: -25px;
            left: 20px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: url("/images/expand.svg") center 10px no-repeat $yellow;
        }

    }

    &.open {
        .sector, .button {
            z-index: 0;
        }
        .rollover {
            background: $blue;
            transform: translateY(-270px);
            border-top: none;
            h3 {
                color: white;
            }

            p {
                color: white;
            }

            .inner {
                background: $blue;
            }

            .scroller {
                overflow: auto;
                max-height: 310px;
                padding-right: 5px;
            }
        }
        
    }
}