/* Colours */
$blue:         #009BCA;
$yellow:       #FABD00;
$red:          #D6342C;
$green:        #C3D21F;
$grey:         #53585A;
$light_grey:   #6D6E70;
$light_blue:   #F0F9FC;
$light_yellow: #FEF2CC;

/* Fonts */
$font: 'vag-rundschrift-d';