.search-form--news {
    .search-form__top {
        height: 100px; 
        button.button {
            display: none;    
        }
    }
    .search-form__colours {
        height: 100%;
        span {
            height: 25%;
        }
    }
    & + .search-form__controls .search-form__pagination--per-page {
        float: right;
    }
}

.news__results {
    margin-top: 15px;
}

.news__results--story {
    background: #FFF;
    position: relative;
    padding-bottom: 80px;
    min-height: 510px;
    margin-bottom: 30px;
    header {
        border-top: 3px solid $blue;
        border-bottom: 1px solid $blue;
        padding: 10px 20px;
        letter-spacing: 3px;
    }
    h3 {
        text-align: left;
        color: $grey;
        margin: 15px 20px 10px 20px;
        @include font-size(12);
        font-weight: 400;
    }
    h4 {
        text-align: left;
        color: $blue;
        margin: 25px 20px 0 20px;
        @include font-size(10);
        font-weight: 400;
    }
    p {
        margin: 0 20px 1em;
        @include font-size(9);
    }
    .scroller {
        max-height: 250px;
        margin-right: 20px;
        overflow: auto;
    }
    .button {
        display: block;
        position: absolute;
        bottom: 20px;
        left: 20px;
        width: calc(100% - 40px);
        margin: 0;
        padding: 8px 0;
        text-align: center;
        @include font-size(9);
    }
}

@media screen and (max-width: 600px) {
    .search-form--news {
        .search-form__top {
            height: auto; 
            margin-bottom: 25px;
            h3 {
                margin-bottom: 15px;
            }
            .row > div:last-child {
                text-align: center;
                label {
                    display: none;
                }
                button {
                    display: none;
                }
                button.button {
                    display: block;
                    margin-top: 25px;
                    color: #FFF;
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
        .search-form__pagination--per-page {
            float: right;
        }
    }
    .news__results--story {
        padding-bottom: 25px;
        .button {
            width: calc(100% - 40px) !important;
            span {
                display: none;
            }
        }
    }
}