.scheme__navigation {
    border-top: 3px solid $yellow;
    & > div {
        max-width: 1600px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
    }
    li {
        flex-grow: 1;
        border-left: 1px solid #EEE;
        a {
            display: block;
            padding: 15px 20px;
            border-bottom: 1px solid #EEE;
            text-align: center;
            text-decoration: none;
            color: #888;
            @include font-size(12);
            &:hover {
                background: #F5F5F5;
            }
            &.active {
                background: $grey;
                color: #FFF;
            }
            @media screen and (max-width: 600px) {
                padding: 15px 10px;
                @include font-size(10);
            }
        }
        &:last-of-type {
            border-right: 1px solid #EEE;
        }
    }
    &.property, &.sticky {
        position: fixed;
        left: 0;
        top: 110px;
        z-index: 300;
        width: 100%;
        background: #FFF;
        
        ul {
            max-width: 1600px;
            flex: 1;
        }
        /*@media screen and (max-width: 600px) {
            top: 154px;
        }*/
    }
}

.scheme__overview {
    h1 {
        text-align: center;
        @include font-size(28);
        font-weight: 300;
        color: $grey;
        border-bottom: 1px solid $blue;
        padding-bottom: 40px;
        margin-bottom: 15px;
        @media screen and (max-width: 600px) {
            @include font-size(22);    
        }
    }
    h2 {
        margin: 25px 0 10px;
        text-align: left;
        @include font-size(14);
        font-weight: 300;
        color: $blue;
    }
    table {
        width: 100%;
        margin-bottom: 10px;
        th, td {
            padding: 5px 8px;
        }
        tr {
            border-bottom: 1px solid #FFF;
        }
        thead {
            th {
                background: $blue;
                font-weight: 400;
                color: #FFF;
            }
        }
        tbody tr {
            &:nth-child(odd) {
                background: #EEE;
            }
            &:nth-child(even) {
                background: $light_blue;
            }
        }
        @media screen and (max-width: 600px) {
            td, th {
                @include font-size(8);
            }
        }
    }
}

.scheme__buttons {
    margin-top: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    .message {
        color: #090;
        margin-bottom: 10px !important;
    }
    & > div {
        display: inline-block;
        margin: 0 15px;
        @media screen and (max-width: 600px) {
            margin: 0 15px 25px 15px;    
        }
    }
    .scheme__downloads {
        width: 250px;
        a {
            text-decoration: none;
            padding: 12px;
            span {
                font-size: 1rem;
                text-transform: none;
                min-height: 200px;
            }
        }
        img {
            display: block;
            margin: 0 auto 20px;
        }
    }
    .scheme__email {
        max-width: 370px;
        padding-top: 28px;
        input {
            display: block;
            border: 1px solid #DDD;
            padding: 8px 10px;
            width: calc(50% - 5px);
            float: left;
            margin: 0 10px 15px 0;
            &:focus {
                outline: none;
                border-color: #BBB;
            }
            &:last-of-type {
                margin-right: 0;
            }
            @media screen and (max-width: 600px) {
                width: 100%;
            }
        }
        p {
            clear: both;
            text-align: left;
            line-height: 120%;
        }
        .button {
            width: 100%;
        }
    }
}

.scheme__siteplan {
    padding: 50px 0 0;
    .row {
        margin-right: 0;
        & > div:last-child {
            background: #F5F5F5;
        }
    }
    .scheme__siteplan--tenancy {
        padding: 20px 5px;
        max-height: 540px;
        overflow: auto;
        .nav-tabs {
            border-bottom: 0;
            margin-bottom: 20px;
            a {
                display: block;
                padding: 10px 35px;
                background: $grey;
                color: #FFF; 
                text-decoration: none;
                border-radius: 0;
                border: none;
                &.active {
                    background: $blue;
                }
            }
        }
        table {
            width: 100%;
            border-spacing: 1px;
            border-collapse: separate;
            th, td {
                padding: 10px 15px;
                @include font-size(9);
                background: #FFF;
                color: $light_grey;
                a {
                    color: $blue;
                }
            }
            th {
                background: #003566;
                color: #FFF;
                font-weight: 400;
            }
            tr:hover td {
                background: $light_blue;
            }
        }
    }
}

#scheme__map {
    min-height: 500px;
    max-height: 670px;
    height: 50vw;
    @media screen and (max-width: 600px) {
        min-height: 300px;    
    }
}

.result__postcode {
    letter-spacing: 1px;
    float: right;
}