@font-face {
    font-family: 'VAG Regular';
    src: url('/css/fonts/VAG Rounded Light.ttf');
}

@font-face {
    font-family: 'VAG Bold';
    src: url('/css/fonts/VAG Rounded Bold.ttf');
}

@font-face {
    font-family: 'VAG Thin';
    src: url('/css/fonts/VAG Rounded Bold.ttf');
}
