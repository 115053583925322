.panel.home__our-services {
    padding-bottom: 0;
    @media screen and (max-width: 480px) {
        padding-bottom: 60px;
    }
}

.our-services__services {
    margin-top: 70px;
    padding: 50px 0 20px;
    figure {
        background-position: center center;
    }
    figcaption {
        border-top-color: $blue;
        border-bottom-color: $blue;
        a {
            @include font-size(11);
            text-align: center;
            padding: 20px 10px;
        }
        &:before {
            display: none;
        }
    }
}

.our_services__service {
    padding-top: 0;
    display: none;
    .panel__content--carousel {
        margin-top: 50px;
    }
    .nav-tabs {
        display: block;
        max-width: 850px;
        margin: 25px auto 0;
        text-align: center;
        border-bottom: 1px solid $blue;
        li {
            display: inline-block;
            margin-bottom: 0;
            border-right: 1px solid $blue;
            &:last-child {
                border-right: none;
            }
            .nav-link {
                border: none;
                padding: 8px 30px;
                font-weight: 300;
                color: #666;
                &.active {
                    font-weight: 500;
                }
            }
        }
    }
}

.our_services__image {
    border-bottom: 3px solid $blue;
}

.our_services__content {
    max-width: 820px !important;
    margin-top: 0 !important;
    margin-bottom: 50px !important;    
    @media screen and (max-width: 600px) {
        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }
    h2, h3 {
        text-align: left;
    }
    h2 {
        @include font-size(18);
        margin: 40px 0 40px;
        font-weight: 300;
    }
    h3 {
        @include font-size(14);
        line-height: 130%;
        max-width: none;
    }
    ul {
        margin-bottom: 15px;
    }
    .tab-pane {
        h3 {
            margin: 30px 0 10px;
        }
        .scroll {
            max-height: 800px;
            padding-right: 25px;
            overflow: auto;
        }
        table {
            margin: 30px 0;
            td, th {
                padding: 10px 0;
            }
            thead th {
                border-top: 1px solid $blue;
                border-bottom: 1px solid $blue;
            }
            tbody tr {
                td {
                    border-bottom: 1px solid #DDD;
                }
                &:last-child td {
                    border-bottom: none;
                }
            }
        }
        &#rate-poundage {
            table:first-of-type tbody tr td:last-child {
                text-align: right;
            }
        }
    }
}

.our_services__agents {
    max-width: 820px !important;
    text-align: center;
    ul {
        margin: 40px auto 40px;
        padding: 0;        
    }
    li {
        list-style: none;
        padding: 0;
        font-weight: 500;
        @include font-size(12);
        a {
            text-decoration: none;
            color: $grey;
        }
        .photo {
            width: 16vw;
            height: 16vw;
            max-width: 190px;
            max-height: 190px;
            min-width: 100px;
            min-height: 100px;
            margin: 0 auto 10px;
            border-radius: 50%;
            background-size: cover;
            background-position: center top;
            background-repeat: no-repeat;
        }
        span {
            display: block;
             margin-bottom: 15px;
            font-weight: 300;
            @include font-size(8);
        }
    }
    .button {
        width: 220px;
        margin-bottom: 70px;
    }
}

@media screen and (min-width: 769px) {
    .dropdown-service-selector {
        display: none;
    }

    .service-icons.service-page {
        margin-bottom: 50px;
        border-bottom: 5px solid #009BCA;
    }
}
@media screen and (max-width: 768px) {
    .service-icons.service-page {
        display: none
    }
}

@media screen and (max-width: 500px) {
    .dropdown-service-selector {
        h4 {
            margin: 40px 0 25px;
        }

        .dropdown button { 
            margin-left: 0;
            width: 100%;
        }
        .dropdown-menu {
            width: 100%!important;
            left:0!important;
        }
        .show-service.button {
            margin-top: 20px;
            width: 100%;
            text-align: center;
        }
    }
}