.our-clients {
    padding-top: 40px;
    padding-bottom: 0;
    @media screen and (max-width: 480px) {
        padding-bottom: 45px;
    }
}
.our-clients__client {
    display: flex;
    align-items: center;
    height: 13.5vw;
    max-height: 172px;
    background: #FFF;
    margin-bottom: 30px;
    text-align: center;
    @media screen and (max-width: 480px) {
        height: 150px;
        width: 150px;
    }
    img {
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }
}